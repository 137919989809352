<template>
	<v-container>
		<h1>Лекція</h1>
		<v-autocomplete
			v-model="lecture.users"
			:items="users"
			label="Учні"
			item-text="name"
			item-value="id"
			multiple
			:loading="loading"
			@change="(val) => updateUsers(lecture.id, val)">
		</v-autocomplete>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'

const lecturesApi = apiFactory.get('lectures')
const lectureUsersApi = apiFactory.get('lectureUsers')

export default {
	props: {
		lectureid: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		lecture: {},
		loading: false,
	}),
	computed: {
		...mapGetters('users', [
			'users',
		]),
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('users', [
			'loadUsers',
		]),
		async loadLecture(id) {
			try {
				const { data } = await lecturesApi.get(id)
				this.lecture = data
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити лекцію',
					color: 'error',
				}, { root: true })
			}
		},
		async updateUsers(id, users) {
			try {
				this.loading = true
				await lectureUsersApi.update(id, users)
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновиити учнів на це заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.loadLecture(this.lectureid)
		this.loadUsers({
			// limit: 10,
			sort: 'asc',
		})
	},
}
</script>